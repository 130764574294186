import css from './Thanks.module.css'
import Heading from '../../elements/Heading/Heading'
import Guide from '../../elements/Guide/Guide'
import Label from '../../elements/Label/Label'
import { alphaTesters, specialThanks } from '../../data/testers'
import Player from '../../elements/Name/Player'
import Button from '../../elements/Button/Button'
import { randomPastel } from '../../utils'

function shuffled(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

function Thanks({ back }) {
  return (
    <div className={css.Thanks}>
      <Heading text={'Hala Sław'} />
      <Guide phase={7} />
      <Label text={'Uczestnicy Alpha'} underline={true} />
      <div className={css.testers}>
        {shuffled(alphaTesters).map((person) => (
          <Player
            key={person}
            id={person}
            name={person}
            color={randomPastel()}
          />
        ))}
      </div>

      <Label text={'Specjalne podziękowania'} underline={true} />
      <div className={css.testers}>
        {shuffled(specialThanks).map((person) => (
          <Player
            key={person}
            id={person}
            name={person}
            color={randomPastel()}
          />
        ))}
      </div>

      <div className={css.wrapper}>
        <Button text={'Zamknij'} click={back} />
      </div>
    </div>
  )
}

export default Thanks
