export const animalNames = {
  en: {
    alpaca: 'alpaca',
    bass: 'bass',
    shrimp: 'shrimp',
    viper: 'viper',
    hog: 'hog',
    capybara: 'capybara',
    llama: 'llama',
    carp: 'carp',
    fox: 'fox',
    frog: 'frog',
    seal: 'seal',
    goat: 'goat',
    wolf: 'wolf',
    spider: 'spider',
    chicken: 'chicken',
    marmot: 'marmot',
    mole: 'mole',
    cat: 'cat',
  },
  pl: {
    alpaca: 'alpaka',
    bass: 'okoń',
    shrimp: 'krewetka',
    viper: 'żmija',
    hog: 'dzik',
    capybara: 'kapibara',
    llama: 'lama',
    carp: 'karp',
    fox: 'lis',
    frog: 'żaba',
    seal: 'foczka',
    goat: 'koza',
    wolf: 'wilk',
    spider: 'pająk',
    chicken: 'kurczak',
    marmot: 'świstak',
    mole: 'kret',
    cat: 'kot',
  },
}
