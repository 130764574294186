import css from './App.module.css'
import GameCreation from './features/GameCreation/GameCreation'
import { useEffect, useState } from 'react'
import { socket } from './services/socket'
import Lobby from './features/Lobby/Lobby'
import { gamePath } from './constants/regexp'
import Gameplay from './features/Gameplay/Gameplay'
import Results from './features/Results/Results'
import Footer from './elements/Footer/Footer'
import Voting from './features/Voting/Voting'
import TopVotes from './features/TopVotes/TopVotes'
import Thanks from './features/Thanks/Thanks'

function App() {
  // socket is active only if gameId is validated
  const [socketActive, setSocketActive] = useState(false)
  const [humanId, setHumanId] = useState('')
  const [game, setGame] = useState({
    id: '',
    setup: {},
    status: '',
    progress: null,
  })
  const [deadApi, setDeadApi] = useState(false)
  const [thanks, setThanks] = useState(false)

  useEffect(() => {
    function onGameEvent(event) {
      setGame(event)
    }

    if (socketActive) {
      socket.auth = { gameId: game.id }
      socket.connect()
      socket.on('EMIT:GAME_DATA', onGameEvent)
    }

    return () => {
      socket.off('EMIT:GAME_DATA', onGameEvent)
      socket.disconnect()
    }
  }, [game.id, socketActive])

  useEffect(() => {
    const localHumanId = window.localStorage.getItem('humanId')
    const localGameId = window.localStorage.getItem('gameId')
    const pathGameId = gatGameIdFromPath()

    // If exists, attempt to validate
    if (localHumanId) {
      validateHuman(localHumanId)
        .then(({ data }) => {
          // happy path, returning human
          if (data.valid) {
            setHumanId(data.id)
          }

          // if not valid, then create new id
          else {
            createHuman().then(({ data }) => {
              window.localStorage.setItem('humanId', data.id)
              setHumanId(data.id)
            })
          }
        })
        .catch(() => {
          setDeadApi(true)
        })
    }

    // no human id, then immediately create one
    else {
      createHuman()
        .then(({ data }) => {
          window.localStorage.setItem('humanId', data.id)
          setHumanId(data.id)
        })
        .catch(() => {
          setDeadApi(true)
        })
    }

    // attempt to validate gameId
    if (localGameId || pathGameId) {
      const gameId = pathGameId || localGameId
      validateGame(gameId)
        .then(({ data }) => {
          // happy path, returning game
          if (data.valid) {
            setGame({
              id: data.id,
              setup: data.setup,
              status: data.status,
              progress: data.progress,
            })
            setSocketActive(true)
          } else {
            setGame({ id: '', setup: {}, status: '', progress: null })
            setSocketActive(false)
          }
        })
        .catch(() => {
          setDeadApi(true)
          setSocketActive(false)
        })
    }
  }, [])

  async function validateHuman(id) {
    const humanIdUrl = `${process.env.REACT_APP_API_URL}/human/${id}`
    const response = await fetch(humanIdUrl)
    return await response.json()
  }

  async function validateGame(id) {
    const url = `${process.env.REACT_APP_API_URL}/game/${id}`
    const response = await fetch(url)
    return await response.json()
  }

  function getGame(data) {
    if (!data) {
      setDeadApi(true)
      setSocketActive(false)
      setGame({ id: '', setup: {}, status: '', progress: null })
    } else {
      window.localStorage.setItem('gameId', data.id)
      setGame({
        id: data.id,
        setup: data.setup,
        status: data.status,
        progress: data.progress,
      })
      setSocketActive(true)
    }
  }

  function getAlias(data) {
    if (!data) {
      setDeadApi(true)
      setSocketActive(false)
      setGame({ id: '', setup: {}, status: '', progress: null })
    } else {
      setGame({
        id: data.id,
        setup: data.setup,
        status: data.status,
        progress: data.progress,
      })
      socket.emit('REQUEST:GAME_DATA')
      setSocketActive(true)
    }
  }

  function initGame(data) {
    if (!data) {
      setGame({ id: '', setup: {}, status: '', progress: null })
      setDeadApi(true)
      setSocketActive(false)
    } else {
      setGame({
        id: data.id,
        setup: data.setup,
        status: data.status,
        progress: data.progress,
      })
      socket.emit('REQUEST:GAME_DATA')
      setSocketActive(true)
    }
  }

  async function createHuman() {
    const newHumanUrl = `${process.env.REACT_APP_API_URL}/human`
    const response = await fetch(newHumanUrl, { method: 'POST' })
    return await response.json()
  }

  function gatGameIdFromPath() {
    const url = new URL(window.location.href)
    const pathValid = gamePath.test(url.pathname)
    let gameId = null

    if (pathValid) {
      // extract gameId from the path
      gameId = url.pathname.substring(6, 42)
      window.localStorage.setItem('gameId', gameId)
    }

    return gameId
  }

  // invoked when the answer is provided
  function getProgress(data) {
    if (!data) {
      setGame({ id: '', setup: {}, status: '', progress: null })
      setDeadApi(true)
      setSocketActive(false)
    } else {
      // TODO: Meybe even this whole setGame is not needed
      setGame({
        id: data.id,
        setup: data.setup,
        status: data.status,
        progress: data.progress,
      })
      socket.emit('REQUEST:GAME_DATA')
      setSocketActive(true)
    }
  }

  function resetGame() {
    window.location.pathname = ''
    window.localStorage.removeItem('gameId')
    setGame({ id: '', setup: {}, status: '', progress: null })
  }
  function showAllAnswers() {
    const updatedSetup = { ...game.setup }
    updatedSetup.modes = []
    setGame({ ...game, setup: updatedSetup })
  }

  function toggleThanks() {
    setThanks(!thanks)
  }

  if (thanks) {
    return (
      <main className={css.App} style={{ height: window.innerHeight }}>
        <Thanks back={toggleThanks} />
        <Footer toggle={toggleThanks} />
      </main>
    )
  }

  if (!deadApi) {
    return (
      <main className={css.App} style={{ height: window.innerHeight }}>
        {!game.id && <GameCreation humanId={humanId} callback={getGame} />}

        {game.status === 'INITIALIZED' && (
          <Lobby
            gameId={game.id}
            players={game.setup.players}
            isPrime={game.setup.primeId === humanId}
            humanId={humanId}
            callback={getAlias}
            initialized={initGame}
          />
        )}

        {game.status === 'PROGRESSING' && (
          <Gameplay
            gameId={game.id}
            humanId={humanId}
            players={game.setup.players}
            questions={game.progress.questions}
            pushProgress={getProgress}
          />
        )}

        {game.status === 'VOTING' && (
          <Voting
            gameId={game.id}
            players={game.setup.players}
            questions={game.progress.questions}
            currentPlayerId={humanId}
            pushProgress={getProgress}
          />
        )}

        {game.status === 'COMPLETED' && game.setup.modes.includes('VOTING') && (
          <TopVotes
            questions={game.progress.questions}
            players={game.setup.players}
            onResetClick={resetGame}
            onShowAllClick={showAllAnswers}
          />
        )}

        {game.status === 'COMPLETED' &&
          !game.setup.modes.includes('VOTING') && (
            <Results
              questions={game.progress.questions}
              players={game.setup.players}
              reset={resetGame}
            />
          )}

        <Footer toggle={toggleThanks} />
      </main>
    )
  }
  return <h1>🚧 Maintenance</h1>
  // Paca peek! Maintenance!
}

export default App
