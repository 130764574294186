import css from './TextAreaForm.module.css'
import { useState } from 'react'
import Label from '../Label/Label'
import Button from '../Button/Button'
import { suggestedQuestions } from '../../locale/suggestions'

const content = {
  QUESTION: {
    label: 'Pytanie',
    buttonText: 'Zadaj je wszystkim',
    buttonDisabledText: 'Najpierw zadaj pytanie',
    placeholder: 'Tutaj napisz pytanie...', // randomize
  },
  ANSWER: {
    label: 'Odpowiedź',
    buttonText: 'Zatwierdź odpowiedź',
    buttonDisabledText: 'Najpierw odpowiedz',
    placeholder: 'Tutaj napisz odpowiedź...',
  },
}

function TextAreaForm(props) {
  const { callback, type, suggestions = [] } = props
  const [value, setValue] = useState('')
  const [suggestedIndex, setSuggestedIndex] = useState(null)

  function updateInput(event) {
    setValue(event.target.value)
  }

  function handleSubmit(event) {
    event.preventDefault()
    setValue('')
    callback(value, type)
  }

  function isDisabled() {
    return value.trim().length <= 1
  }

  function triggerSuggestion() {
    const maxIndex = suggestions.length - 1

    if (suggestedIndex === null || suggestedIndex === maxIndex) {
      setSuggestedIndex(0)
      setValue(suggestedQuestions.pl[0])
      return
    }

    setSuggestedIndex(suggestedIndex + 1)
    setValue(suggestedQuestions.pl[suggestedIndex + 1])
  }

  return (
    <form
      className={css.TextAreaForm}
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <Label text={content[type].label} />
      <div className={css.TextAreaWrap}>
        <textarea
          id={type}
          name={type}
          value={value}
          placeholder={content[type].placeholder}
          onChange={updateInput}
          maxLength={240}
        />
      </div>
      <div className={css.DynamicButtonWrapper}>
        <Button
          click={triggerSuggestion}
          text={'Losuj'}
          disabled={type !== 'QUESTION'}
        />

        <Button
          click={handleSubmit}
          text={
            isDisabled()
              ? content[type].buttonDisabledText
              : content[type].buttonText
          }
          disabled={isDisabled()}
        />
      </div>
    </form>
  )
}

export default TextAreaForm
