import css from './Gameplay.module.css'
import TextAreaForm from '../../elements/TextAreaForm/TextAreaForm'
import Guide from '../../elements/Guide/Guide'
import Heading from '../../elements/Heading/Heading'
import Label from '../../elements/Label/Label'
import ProgressLine from '../../elements/ProgressLine/ProgressLine'
import { progressLineAnswers } from '../../utils'

function Gameplay(props) {
  const { questions, humanId, gameId, pushProgress, players } = props

  function alreadyAsked(playerId) {
    let asked = false
    for (const question of questions) {
      if (question.humanId === playerId) {
        asked = true
      }
    }
    return asked
  }

  // type can be QUESTION or ANSWER
  function collectValue(value, type) {
    if (type === 'QUESTION') {
      askQuestion(value).then(({ data }) => {
        pushProgress(data)
      })
    } else if (type === 'ANSWER') {
      answerQuestion(value).then(({ data }) => {
        pushProgress(data)
      })
    }
  }

  function getSuggestions() {
    let suggestions = [0]
    const currentPlayer = players.find((player) => player.id === humanId)
    if (currentPlayer) {
      suggestions = currentPlayer.suggestions
    }
    return suggestions
  }

  async function askQuestion(text) {
    const url = `${process.env.REACT_APP_API_URL}/game/progress/question`
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        game: { id: gameId },
        question: { humanId, text, answers: [] },
      }),
    })
    return await response.json()
  }

  async function answerQuestion(text) {
    const url = `${process.env.REACT_APP_API_URL}/game/progress/question/answer`
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        game: { id: gameId },
        question: { humanId: oldestUnansweredQuestion(humanId).humanId }, // not mine
        answer: { humanId, text },
      }),
    })
    return await response.json()
  }

  function oldestUnansweredQuestion(targetHumanId) {
    let oldestQuestion = null
    let oldestTimestamp = Infinity

    for (const question of questions) {
      // Check if the question has answers with the targetHumanId
      const hasAnswer = question.answers.some(
        (answer) => answer.humanId === targetHumanId
      )

      if (!hasAnswer && Date.parse(question.timestamp) < oldestTimestamp) {
        oldestTimestamp = Date.parse(question.timestamp)
        oldestQuestion = question
      }
    }

    return oldestQuestion
  }

  function renderHeading() {
    if (!alreadyAsked(humanId)) {
      return <Heading text={'Pytaj o cokolwiek!'} />
    } else if (alreadyAsked(humanId) && !oldestUnansweredQuestion(humanId)) {
      return <Heading text={'Skończyłeś.'} />
    } else {
      return <Heading text={'W zamian za odpowiedzi...'} />
    }
  }

  return (
    <div className={css.Gameplay}>
      {renderHeading()}

      <div>
        {!alreadyAsked(humanId) && (
          <>
            <Guide phase={4} />
            <TextAreaForm
              callback={collectValue}
              type={'QUESTION'}
              suggestions={getSuggestions()}
            />
          </>
        )}

        {alreadyAsked(humanId) && oldestUnansweredQuestion(humanId) && (
          <div>
            <Label text={'PYTANIE'} />
            <div className={css.Question}>
              {oldestUnansweredQuestion(humanId).text}
              {oldestUnansweredQuestion(humanId).humanId === humanId &&
                questions[0].humanId === humanId && (
                  <p>
                    PS. Jesteś pierwszą osobą, która zadała pytanie! Na swoje
                    także trzeba odpowiedzieć.
                  </p>
                )}
            </div>

            <TextAreaForm callback={collectValue} type={'ANSWER'} />
          </div>
        )}

        {alreadyAsked(humanId) && !oldestUnansweredQuestion(humanId) && (
          <>
            <Guide phase={5} />
            <div className={css.LineWrapper}>
              <ProgressLine
                total={progressLineAnswers(questions).total}
                done={progressLineAnswers(questions).done}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Gameplay
