import css from './Heading.module.css'

function Heading({ text }) {
  return (
    <div className={css.Heading}>
      <h1>{text}</h1>
    </div>
  )
}

export default Heading
