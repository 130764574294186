import { animalNames } from '../locale/animals'

export function notTooLongName(name) {
  return name.substring(0, 6)
}

export function askingName(name) {
  return `❓ ${name.substring(0, 3)}`
}

export function playerName(playerId, players) {
  let name = 'Anon'

  for (const player of players) {
    if (player.id === playerId) {
      name = player.name
      return name
    }
  }

  return name
}

export function playerAnimal(playerId, players, locale = 'en') {
  let animal = 'animal'

  for (const player of players) {
    if (player.id === playerId) {
      animal = player.animal
      return animalNames[locale]?.[animal] || animal
    }
  }

  return animal
}

export function calculateTopAnswerers(questions) {
  const votesMap = {}

  // Extend the structure to keep track of questionIds for each vote
  questions.forEach((question) => {
    question.votes.forEach((vote) => {
      if (!votesMap[vote.answererId]) {
        votesMap[vote.answererId] = { count: 0, questionIds: new Set() }
      }
      votesMap[vote.answererId].count += 1
      votesMap[vote.answererId].questionIds.add(question.humanId)
    })
  })

  // Convert to an array
  const voteCountsArray = Object.entries(votesMap).map(
    ([answererId, data]) => ({
      answererId,
      count: data.count,
      questionIds: Array.from(data.questionIds),
    })
  )

  // Sort by count descending
  voteCountsArray.sort((a, b) => b.count - a.count)

  // Find the maximum vote count
  const maxVoteCount = voteCountsArray[0]?.count

  // Filter answerers who have the same vote count as the maximum
  return voteCountsArray.filter((answerer) => answerer.count === maxVoteCount)
}

export function topVotedAnswersWithDetails(questions) {
  const answersMap = {}

  // Step 1: Accumulate votes for each answer, including text and question text
  questions.forEach((question) => {
    question.votes.forEach((vote) => {
      const key = `${vote.questionId}-${vote.answererId}`
      if (!answersMap[key]) {
        // Find the text of the answer by matching the humanId
        const answerText =
          question.answers.find((answer) => answer.humanId === vote.answererId)
            ?.text || 'Answer not found'
        answersMap[key] = {
          count: 0,
          questionId: vote.questionId,
          answererId: vote.answererId,
          answerText: answerText,
          questionText: question.text,
        }
      }
      answersMap[key].count += 1
    })
  })

  // Step 2: Convert to an array, sort by count descending
  return Object.values(answersMap)
    .sort((a, b) => b.count - a.count)
    .slice(0, 3)
}

export function hasVotedOnAllQuestions(voterId, questions) {
  return questions.every((question) =>
    question.votes?.some((vote) => vote.voterId === voterId)
  )
}

export function progressLineProps(questions) {
  // Step 1: Check if any question is missing the .votes property
  if (questions.some((question) => !question.votes)) {
    return {
      total: questions.length,
      done: 0,
    }
  }

  // Step 2: Identify all unique voterIds across all questions
  const uniqueVoterIds = new Set()
  questions.forEach((question) => {
    question.votes.forEach((vote) => {
      uniqueVoterIds.add(vote.voterId)
    })
  })

  // Step 3: Count how many of these voters have voted on all questions
  const votersCompleted = Array.from(uniqueVoterIds).filter((voterId) =>
    questions.every((question) =>
      question.votes.some((vote) => vote.voterId === voterId)
    )
  ).length

  return {
    total: questions.length,
    done: votersCompleted,
  }
}

export function progressLineAnswers(questions) {
  const totalQuestions = questions.length
  const questionsWithAllAnswers = questions.filter(
    (question) => question.answers && question.answers.length === totalQuestions
  ).length

  return {
    total: totalQuestions,
    done: questionsWithAllAnswers,
  }
}

export function getPlayerColor(playerId, players) {
  const player = players.find((player) => player.id === playerId)
  return player ? player.color : '#C76262'
}

export function addVoteCountsToAnswers(questions) {
  return questions.map((question) => {
    const voteCounts = {}

    // Count votes
    if (question?.votes) {
      question.votes.forEach((vote) => {
        if (!voteCounts[vote.answererId]) {
          voteCounts[vote.answererId] = 0
        }
        voteCounts[vote.answererId]++
      })
    }

    // Assign vote counts to answers
    const answersWithVoteCounts = question.answers.map((answer) => ({
      ...answer,
      voteCount: voteCounts[answer.humanId] || 0, // Add voteCount property
    }))

    return { ...question, answers: answersWithVoteCounts }
  })
}

export function randomHue(hex) {
  // Remove the '#' if present
  hex = hex.replace(/^#/, '')

  // Convert hex to an integer
  const colorInt = parseInt(hex, 16)

  // Cycle the integer within 0-360 for the hue
  const hue = colorInt % 360

  // Set fixed saturation, lightness, and alpha values for a pastel color
  const saturation = 100
  const lightness = 89
  const alpha = 1

  // Return the HSLA color string
  return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`
}

export function randomPastel() {
  // Generate a random hue from 0 to 360
  const randomHue = Math.floor(Math.random() * 360)
  // Set fixed saturation, lightness, and alpha values for a pastel color
  const saturation = 100
  const lightness = 91
  const alpha = 1

  // Return the HSLA color string
  return `hsla(${randomHue}, ${saturation}%, ${lightness}%, ${alpha})`
}
