import { useState } from 'react'

function CheckboxInput(props) {
  const { name, pushConfig, disabled } = props
  const [checked, setChecked] = useState(true)

  function handleChange() {
    pushConfig({ voting: !checked })
    setChecked(!checked)
  }

  return (
    <label style={{ display: 'none' }} htmlFor={name}>
      <input
        id={name}
        type={'checkbox'}
        name={name}
        onChange={handleChange}
        checked={disabled ? false : checked}
        disabled={disabled}
      />
      {disabled ? 'Voting Mode requires at least 3 players' : 'Voting'}
    </label>
  )
}

export default CheckboxInput
