import image from '../../images/icecube.png'
import css from './Guide.module.css'

const config = [
  {
    src: image,
    text: 'Utwórz grę, w której każdy zada pytanie i każdy na nie odpowie. Następnie gracze będą głosować na odpowiedzi, nie wiedząc, kto je napisał. Na końcu ujawnisz wyniki, pokazując, co Was łączy, a co dzieli.',
  },
  {
    src: image,
    text: 'Zaproś co najmniej 2 znajomych, aby rozpocząć grę. Użyj przycisku "+ Zaproś kogoś", by skopiować link z zaproszeniem.',
  },
  {
    src: image,
    text: 'Każdy zadaje pytanie i każdy odpowiada. Następnie głosujemy na odpowiedzi, nie wiedząc, kto je napisał. Na końcu ujawniane są wyniki, pokazując, co nas łączy, a co dzieli.',
  },
  {
    src: image,
    text: 'Imiona zostały zamienione na nazwy zwierząt. Teraz zadaj pytanie i odpowiedz na wszystkie, nie wiedząc, kto kim jest. Głosuj bez uprzedzeń – wyniki pokażą, co nas łączy, a co dzieli.',
  },
  {
    src: image,
    text: "Pytania otwarte dają lepsze odpowiedzi – unikaj takich, na które można odpowiedzieć tylko 'tak' lub 'nie'. Wciąż jesteś anonimowy.\n",
  },
  {
    src: image,
    text: 'Poczekajmy na tych, którzy jeszcze myślą... Zielone paski reprezentują pytania, które uzyskały wszystkie odpowiedzi.',
  },
  {
    src: image,
    text: 'Zaraz wyświetlą się wyniki. Zielone paski reprezentują osoby, które ukończyły głosowanie.',
  },
  {
    src: image,
    text: 'Uznanie dla zasłużonych, którzy używali aplikacji podczas testów. Kolejność losowa.',
  },
]

function Guide(props) {
  const { phase = 0 } = props
  return (
    <div className={css.Guide}>
      <p>{config[phase].text}</p>
    </div>
  )
}

export default Guide
