import css from './Footer.module.css'

function Footer({ toggle }) {
  return (
    <footer className={css.Footer}>
      <p>Alpha {process.env.REACT_APP_VERSION} | </p>
      <button onClick={toggle}>🤍</button>
    </footer>
  )
}

export default Footer
