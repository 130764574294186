import css from './Button.module.css'

function Button({ click = () => {}, disabled = false, text }) {
  return (
    <>
      <button
        className={css.Button}
        onClick={click}
        type={'submit'}
        disabled={disabled}
      >
        {text}
      </button>
    </>
  )
}

export default Button
