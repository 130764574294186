import css from './Label.module.css'

function Label({ text, underline = false }) {
  return (
    <div data-underline={underline} className={css.Label}>
      <p>{text}</p>
    </div>
  )
}

export default Label
