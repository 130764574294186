export const suggestedQuestions = {
  en: [
    'What is your biggest achievement?',
    'What trait do you value most in others?',
    'What would you like to achieve?',
    'What is your greatest strength?',
    'What is the most important thing you have learned?',
    'What are you most proud of?',
    'What are your three most important values?',
    'What are your nearest goals?',
    'What makes you feel truly alive?',
    'If you had one year left to live, what would you do?',
    'What do you dream about?',
    'What are you afraid of when you think about the future?',
    'What do you hope to experience by the end of your life?',
    'What do you not accept about yourself?',
    'What brings you daily joy?',
    'Who has influenced your life the most, and why?',
    'What act of kindness were you once offered, and why was it important to you?',
    'What is your favorite way to spend a day?',
    'What things make you smile?',
    'What surprises you the most about other people?',
    'What would you like to learn?',
    'What would you like to teach others?',
    'If you could relive one moment, which would it be, and why?',
    'What kind of impact do you want to leave behind?',
    'What does freedom mean to you?',
    'What emotion do you wish you could feel more often?',
    'What would you tell your younger self if you had the chance?',
    'What would you tell your future-self if you had the chance?',
    'What’s something mysterious about you that even you don’t fully understand?',
    'What do you think people misunderstand most about you?',
    'If you could invite three people, living or dead, to dinner, who would they be and why?',
    'What would a perfect day look like for you from start to finish?',
    'What’s a small habit you wish you could adopt every day?',
    'How would you describe happiness in just three words?',
    'What makes you feel connected to others?',
    'If you had to describe yourself as a color, which would you choose and why?',
    'What would you do differently if you knew no one would judge you?',
    'If you could give everyone in the world one quality, what would it be and why?',
    'What’s one thing you’re really curious about right now?',
    'If you could live in any era of history, which one would you choose and why?',
    'What’s a question you would love to know the answer to?',
    'What’s one experience you think everyone should have in their life?',
    'If you could change one thing about how people treat each other, what would it be?',
    'If you could wake up tomorrow with one new skill, what would it be?',
    'What’s something you believe that others might find unusual?',
    'What’s your favorite memory?',
    'If your life were a book, what title would you give it?',
    'Would you rather rewind or fast-forward time? Describe why.',
  ],
  pl: [
    'Jaki jest Twój największy sukces?',
    'Jaką cechę najbardziej cenisz w innych?',
    'Co chciałbyś osiągnąć?',
    'Co jest Twoją największą zaletą?',
    'Jaka jest najważniejsza rzecz, jakiej się nauczyłeś?',
    'Z czego jesteś najbardziej dumny?',
    'Jakie są Twoje trzy najważniejsze wartości?',
    'Jakie są Twoje najbliższe cele?',
    'Co sprawia, że czujesz, że żyjesz?',
    'Gdyby został Ci rok życia, to co byś wtedy zrobił?',
    'O czym marzysz?',
    'Czego się boisz myśląc o przyszłości?',
    'Czego masz nadzieję doświadczyć pod koniec swojego życia?',
    'Czego w sobie nie akceptujesz?',
    'Co sprawia Ci radość na codzień?',
    'Kto i dlaczego wywarł wpływ na Twoje życie?',
    'Jaki akt dobroci został Ci kiedyś oferowany i dlaczego był dla Ciebie ważny?',
    'Jaki jest Twój ulubiony sposób spędzania dnia?',
    'Jakie rzeczy sprawiają, że się uśmiechasz?',
    'Co Cię najbardziej zaskakuje u innych ludzi?',
    'Czego chciałbyś się nauczyć?',
    'Czego chciałbyś nauczyć innych?',
    'Gdybyś mógł przeżyć jeden moment jeszcze raz, jaki by to był i dlaczego?',
    'Jaki wpływ chciałbyś pozostawić po sobie?',
    'Co dla Ciebie oznacza wolność?',
    'Jakie emocje chciałbyś częściej odczuwać?',
    'Co powiedziałbyś sobie z przeszłości, gdybyś miał taką szansę?',
    'Co powiedziałbyś sobie z przyszłości, gdybyś miał taką szansę?',
    'Co jest w Tobie tajemnicze, czego nawet Ty nie do końca rozumiesz?',
    'Co Twoim zdaniem ludzie najczęściej źle o Tobie rozumieją?',
    'Gdybyś mógł zaprosić na kolację trzy osoby, żywe lub zmarłe, kto by to był i dlaczego?',
    'Jak wyglądałby Twój idealny dzień od początku do końca?',
    'Jaki drobny nawyk chciałbyś przyjąć każdego dnia?',
    'Jak opisałbyś szczęście w trzech słowach?',
    'Co sprawia, że czujesz się połączony z innymi?',
    'Gdybyś miał opisać siebie jako kolor, jaki byś wybrał i dlaczego?',
    'Co byś zrobił inaczej, gdybyś wiedział, że nikt Cię nie oceni?',
    'Gdybyś mógł dać wszystkim ludziom na świecie jedną cechę, co by to było i dlaczego?',
    'Co najbardziej ciekawi Cię obecnie?',
    'Gdybyś mógł żyć w dowolnej epoce historycznej, którą byś wybrał i dlaczego?',
    'Na jakie pytanie bardzo chciałbyś poznać odpowiedź?',
    'Jaka jest jedna rzecz, której uważasz, że każdy powinien doświadczyć w swoim życiu?',
    'Gdybyś mógł zmienić jedną rzecz w sposobie, w jaki ludzie się traktują, co by to było?',
    'Gdybyś mógł obudzić się jutro z nową umiejętnością, jaka by to była?',
    'W co wierzysz, co inni mogą uznać za nietypowe?',
    'Jakie jest Twoje ulubione wspomnienie?',
    'Gdyby Twoje życie było książką, jaki nadałbyś jej tytuł?',
    'Wolałbyś cofać, czy przyśpieszać czas? Opisz dlaczego.',
  ],
}
