import css from './InviteButton.module.css'

function InviteButton(props) {
  const { gameId } = props

  function fullUrl(gameId) {
    return window.location.href + 'game/' + gameId
  }

  function copyValue(event) {
    event.preventDefault()
    navigator.clipboard.writeText(fullUrl(gameId))
    // TODO: Add notification when Copied
    // TODO: Different behaviour on mobile & Desktop
  }

  return (
    <button className={css.InviteButton} onClick={copyValue} type={'submit'}>
      + Zaproś kogoś
    </button>
  )
}

export default InviteButton
