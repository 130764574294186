import css from './VotableAnswers.module.css'
import { useEffect, useState } from 'react'
import { getPlayerColor, playerAnimal, randomHue } from '../../utils'
import Player from '../Name/Player'

function VotableAnswers(props) {
  const { question, pushVote, voterId, alreadySelectedId, players } = props
  const [selectedId, setSelected] = useState(alreadySelectedId)

  function selectAnswer(answererId) {
    setSelected(answererId)
    pushVote(answererId)
  }

  useEffect(() => {
    setSelected(alreadySelectedId)
  }, [question, alreadySelectedId])

  return (
    <div className={css.VotableAnswers}>
      {question.answers.map((answer) => {
        if (answer.humanId !== voterId) {
          return (
            <div className={css.answer} key={answer.humanId}>
              <input
                checked={selectedId === answer.humanId}
                id={answer.humanId}
                value={answer.text}
                name={'ANSWER'}
                type="radio"
                onChange={() => selectAnswer(answer.humanId)}
              />
              <label htmlFor={answer.humanId} key={answer.humanId}>
                <Player
                  id={answer.humanId}
                  name={playerAnimal(answer.humanId, players, 'pl')}
                  color={randomHue(getPlayerColor(answer.humanId, players))}
                />
                <p>{answer.text}</p>
              </label>
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

export default VotableAnswers
