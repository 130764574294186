import css from './Lobby.module.css'
import SingleInputForm from '../../elements/SingleInputForm/SingleInputForm'
import Button from '../../elements/Button/Button'
import CheckboxInput from '../../elements/CheckboxInput/CheckboxInput'
import { useEffect, useState } from 'react'
import Guide from '../../elements/Guide/Guide'
import Heading from '../../elements/Heading/Heading'
import WaitingPlayers from '../../elements/WaitingPlayers/WaitingPlayers'

function Lobby(props) {
  const { players, gameId, isPrime, humanId, callback, initialized } = props
  const votingModeAvailable = players.length > 2
  // set voting as default after refresh
  const [modes, setModes] = useState(votingModeAvailable ? ['VOTING'] : [])

  useEffect(() => {
    // set voting mode in case of real-time update
    if (players.length === 3) {
      setModes(['VOTING'])
    }
  }, [players])

  async function joinAsNewPlayer(gameId, humanId, humanAlias) {
    const url = `${process.env.REACT_APP_API_URL}/game`
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        human: { id: humanId, alias: humanAlias },
        gameId: gameId,
        game: { id: gameId, status: null },
      }),
    })
    return await response.json()
  }

  async function startGameAsPrimePlayer(gameId, humanId) {
    const url = `${process.env.REACT_APP_API_URL}/game/progress`
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        human: { id: humanId },
        game: { id: gameId, status: 'PROGRESSING', setup: { modes } },
      }),
    })
    return await response.json()
  }

  function collectPlayerAlias(alias) {
    joinAsNewPlayer(gameId, humanId, alias)
      .then(({ data }) => {
        callback(data)
      })
      .catch(() => {
        // TODO: Give options, display meaningful info on FE
        // player may join next, ot return to current game
        callback(null)
      })
  }

  function playerAlreadyJoined() {
    let playerFound = false

    for (const player of players) {
      if (player.id === humanId) {
        playerFound = true
      }
    }

    return playerFound
  }

  function onStartButtonClick() {
    startGameAsPrimePlayer(gameId, humanId)
      .then(({ data }) => {
        initialized(data)
      })
      .catch(() => {
        // TODO: Give options, display meaningful info on FE
        // prime can start only one game at once
        initialized(null)
      })
  }

  function getConfig(config) {
    const uniqueModes = new Set(modes)
    const { voting } = config

    // update voting mode
    if (voting) {
      uniqueModes.add('VOTING')
    } else {
      uniqueModes.delete('VOTING')
    }

    setModes([...uniqueModes])
  }

  return (
    <div className={css.Lobby}>
      <Heading text={'Kto powiedział, że się znamy?'} />

      {isPrime && players.length === 1 ? (
        <Guide phase={1} />
      ) : (
        <Guide phase={2} />
      )}

      <WaitingPlayers players={players} gameId={gameId} />

      {isPrime && (
        <CheckboxInput
          pushConfig={getConfig}
          name={'voting'}
          disabled={!votingModeAvailable}
        />
      )}

      {isPrime && (
        <div className={css.ButtonWrapper}>
          <Button
            click={onStartButtonClick}
            disabled={players.length <= 2}
            text={
              players.length <= 2 ? 'Daj innym dołączyć...' : 'Wystartuj grę'
            }
          />
        </div>
      )}

      {!isPrime && playerAlreadyJoined() && (
        <div className={css.ButtonWrapper}>
          <Button disabled={true} text={'Czekamy na innych...'} />
        </div>
      )}

      {!playerAlreadyJoined() && (
        <SingleInputForm callback={collectPlayerAlias} buttonText={'Dołącz'} />
      )}
    </div>
  )
}

export default Lobby
