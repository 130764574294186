import css from './Player.module.css'

function Player({ id, name, color, number, small = false }) {
  return (
    <div
      className={css.Player}
      aria-labelledby={id}
      style={{ backgroundColor: color }}
      data-small={small}
    >
      <p>{name}</p>
      {number && <p className={css.num}>{number}</p>}
    </div>
  )
}

export default Player
