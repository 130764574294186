import SingleInputForm from '../../elements/SingleInputForm/SingleInputForm'
import css from './GameCreation.module.css'
import Guide from '../../elements/Guide/Guide'
import Heading from '../../elements/Heading/Heading'

function GameCreation(props) {
  const { humanId, callback } = props

  async function createGame(humanAlias) {
    const url = `${process.env.REACT_APP_API_URL}/game`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ human: { id: humanId, alias: humanAlias } }),
    })
    return await response.json()
  }

  function handleSubmit(humanAlias) {
    // attempt game creation
    createGame(humanAlias)
      .then(({ data }) => {
        window.localStorage.setItem('gameId', data.id)
        callback(data)
      })
      .catch(() => {
        callback(null)
      })
  }

  return (
    <div className={css.GameCreation}>
      <Heading text={'Kto powiedział, że się znamy?'} />
      <Guide phase={0} />
      <SingleInputForm callback={handleSubmit} />
    </div>
  )
}

// TODO: Rename to initialization
export default GameCreation
