import css from './ProgressLine.module.css'

const ProgressLine = ({ total, done }) => {
  return (
    <div className={css.ProgressLine}>
      {Array.from({ length: total }).map((_, index) => (
        <div key={index} data-done={index < done} />
      ))}
    </div>
  )
}

export default ProgressLine
