import css from './WaitingPlayers.module.css'
import InviteButton from '../InviteButton/InviteButton'
import Label from '../Label/Label'
import Player from '../Name/Player'

function WaitingPlayers({ players, gameId }) {
  return (
    <>
      <Label text={'Gracze'} underline={true} />
      <div className={css.WaitingPlayers}>
        {players.map((player) => (
          <Player
            color={player.color}
            id={player.id}
            key={player.id}
            name={player.name}
          />
        ))}

        <div>
          <InviteButton gameId={gameId} />
        </div>
      </div>
    </>
  )
}

export default WaitingPlayers
